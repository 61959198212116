import axios from "axios";
import { result } from "lodash";
import {
  API_SERVER_URL,
  DEFAULT_JWT_TOKEN,
  DEFAULT_MASTER_MODULE,
  API_SERVER_URL_DEV,
} from "./app_constants";

// axios.defaults.baseURL = 'http://myurl';
// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default {
  fileUpload: {
    uploadImageFile: (referenceId, formdata) =>
      axios
        .post(
          `https://s3util.herokuapp.com/documents?refId=${referenceId}`,
          formdata,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          return res.data;
        }),
    uploadVideoFile: (referenceId, formdata) =>
      axios
        .post(
          `https://s3util.herokuapp.com/videos?refId=${referenceId}`,
          formdata,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          return res.data;
        }),
  },
  auth: {
    login: (loginData) =>
      axios
        .post(API_SERVER_URL + "/api/admin/gu/auth/login", loginData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
    refreshToken: (refresh_token) =>
      axios
        .post(
          API_SERVER_URL + "/auth/token/refresh",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${refresh_token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),
    createAccount: (accountData) =>
      axios
        .post(API_SERVER_URL + "/auth/registration", accountData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          return res.data;
        }),
    updateAccountDetails: (token, accountData) =>
      axios
        .put(API_SERVER_URL + "/auth/registration", accountData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(
            "==========>>> api.js ========== updateAccountDetails >>> accountData:",
            accountData
          );
          console.log(
            "==========>>> api.js ========== updateAccountDetails >>> err:",
            err
          );
          // return res.data;
        }),
    verifyOTP: (otpData) =>
      axios
        .post(API_SERVER_URL + "/auth/verify_otp", otpData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          return res.data;
        }),
    resendOTP: (otpData) =>
      axios
        .post(API_SERVER_URL + "/auth/send_otp", otpData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          return res.data;
        }),
    sendForgotPasswordOTP: (otpData) =>
      axios
        .post(API_SERVER_URL + "/auth/sendPasswordResetOtp", otpData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          return res.data;
        }),
    setNewPassword: (passwordData) =>
      axios
        .post(API_SERVER_URL + "/auth/newPassword", passwordData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          return res.data;
        }),
  },
  master: {
    getAllMastersForJobDirectory: (authToken) =>
      axios
        .get(
          API_SERVER_URL + "/api/admin/lu/get_all_masters_for_job_directory",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),
  },
  job: {
    addNewJob: (jobData, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/job", jobData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
    saveJobDetails: (jobData, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job/details", jobData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
    saveJobResponsibilities: (jobData, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job/responsibilities", jobData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
    saveJobRequirements: (jobData, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job/requirements", jobData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),

    getJobListByStatus: (status, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/job/list/" + status, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
    getJobDetailById: (jobId, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/job/" + jobId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),

    uploadJobImages: (formData, authToken) =>
      // {console.log("in API.uploADjobImages ==>");
      // for (var key of formData.entries()) {
      //   console.log(key[0] + ', ' + key[1]);
      // }}
      axios
        .post(API_SERVER_URL + "/api/admin/lu/upload/job/images", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=${formData._boundary}",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),

    saveJobImagePath: (jobData, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job/image_paths/", jobData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),

    uploadDefaultJobImages: (formData, authToken) =>
      axios
        .post(
          API_SERVER_URL + "/api/admin/lu/upload/job/default_images",
          formData,
          {
            headers: {
              "Content-Type":
                "multipart/form-data; boundary=${formData._boundary}",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),

    saveDefaultJobImagePath: (jobData, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/default_image", jobData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),

    defaultJobImageByStatus: (status, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/default_image/status/" + status, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> default_image_path_by_status : ", res);
          return res.data;
        }),

    defaultImageChangeStatus: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/default_image/status", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> default_image_path : ", res);
          return res.data;
        }),

    getDefaultImageById: (imageId, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/default_image/" + imageId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API call =====>> default_image_BY_Id : ", result);
          return result.data;
        }),

    updateDefaultImage: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/default_image", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API call =====>>upafate_default_image : ", result);
          return result.data;
        }),

    getDefaultImageByDirectoryId: (directoryId, authToken) =>
      axios
        .get(
          API_SERVER_URL +
            "/api/admin/lu/default_image/directoryId/" +
            directoryId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((result) => {
          console.log("API call =====>> default_image_BY_Id : ", result);
          return result.data;
        }),

    addRejectionReason: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/job_rejection", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> Rejection reason : ", res);
          return res.data;
        }),

    getRejectionReasonList: (statusId, authToken) =>
      axios
        .get(
          API_SERVER_URL + "/api/admin/lu/job_rejection/status/" + statusId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call =====>> Rejection reason list: ", res);
          return res.data;
        }),

    changeRejectionReasonStatus: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job_rejection/status", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log(
            "API call =====>> change status of rejection reason  : ",
            res
          );
          return res.data;
        }),

    getActiveRejectionReasonList: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu//job_rejection", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>>Active Rejection reason list: ", res);
          return res.data;
        }),

    updateRejectionReason: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job_rejection", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> edit/update rejection reason  : ", res);
          return res.data;
        }),

    getRedeemRequest: (status, authToken) =>
      axios
        .get(
          API_SERVER_URL +
            "/api/admin/lu/reward_points/redeem_request/status/" +
            status,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call ===>>REDDEM REQ ", res);
          return res.data;
        }),

    dispatchRedeemRequest: (data, authToken) =>
      axios
        .put(
          API_SERVER_URL + "/api/admin/lu/reward_points/redeem_request/status",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call ===>>REDDEM REQ STATUS CHNAGE", res);
          return res.data;
        }),

    getFirstRewardList: (status, authToken) =>
      axios
        .get(
          API_SERVER_URL +
            "/api/admin/lu/job_application/first_job_reward_request/" +
            status,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call ===>>First job redeem ", res);
          return res.data;
        }),

    giveRewardForFirstJob: (data, authToken) =>
      axios
        .put(
          API_SERVER_URL +
            "/api/admin/lu/job_application/first_job_reward_request",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call ===>>Give Reward for First job redeem ", res);
          return res.data;
        }),

    changeJobStatus: (jobData, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job/status/", jobData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> changr job status : ", res);
          return res.data;
        }),

    contract_Type: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/job_contract_type", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> contract type : ", res);
          return res.data;
        }),

    getContractTypeList: (status, authToken) =>
      axios
        .get(
          API_SERVER_URL + "/api/admin/lu/job_contract_type/status/" + status,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call =====>> contract type : ", res);
          return res.data;
        }),

    changeContractTypeStatus: (status, authToken) =>
      axios
        .put(
          API_SERVER_URL + "/api/admin/lu/job_contract_type/status",
          status,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call =====>> change contract type status : ", res);
          return res.data;
        }),

    editContractType: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job_contract_type", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> edit contract type  : ", res);
          return res.data;
        }),

    location: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/job_location", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> contract type : ", res);
          return res.data;
        }),

    getLocationList: (status, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/job_location/status/" + status, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>>  location : ", res);
          return res.data;
        }),

    changeLocationStatus: (status, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job_location/status", status, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> change location status : ", res);
          return res.data;
        }),

    editLocation: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job_location", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call =====>> edit location   : ", res);
          return res.data;
        }),

    addSkill: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/cv_skill", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> skill", res);
          return res.data;
        }),

    showSkillList: (status, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/cv_skill/status/" + status, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> skill list", res);
          return res.data;
        }),

    changeSkillStatus: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/cv_skill/status", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> skill status", res);
          return res.data;
        }),

    editSkill: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/cv_skill", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> skill edit", res);
          return res.data;
        }),

    addIndustry: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/industry", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> industry add", res);
          return res.data;
        }),

    industryList: (status, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/industry/status/" + status, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> industry list", res);
          return res.data;
        }),

    changeIndustryStatus: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/industry/status", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> industry status", res);
          return res.data;
        }),

    changeIndustryStatus: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/industry/status", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> industry status", res);
          return res.data;
        }),

    updateIndustry: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/industry", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> industry edit", res);
          return res.data;
        }),
  },

  jobDirectory: {
    getEmployer: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/employer", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call====>> companyName: ", res);
          return res.data;
        }),

    changeEmployerStatus: async (authToken, body) => {
      const res = await axios.put(
        API_SERVER_URL + "/api/admin/lu/employer/status",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("API Call =====>> companyName: ", res);
      return res.data;
    },

    getCategory: async (authToken, body) =>
      await axios
        .get(
          API_SERVER_URL + "/api/admin/lu/directory/:directoryId/category",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call====>> categoryList: ", res);
          return res.data;
        }),
  },

  directoryCategory: {
    createDirectoryCategory: (DirectoryCategoryData, authToken) =>
      axios
        .post(
          API_SERVER_URL + "/api/admin/lu/directory/category",
          DirectoryCategoryData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log(
            "==========>>> api.js ==========CreateDirectoryCategory >>> res:",
            res
          );
          return res.data;
        }),

    getEmployer: async (authToken) =>
      await axios
        .get(API_SERVER_URL + "/api/admin/lu/employer", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call====>> companyName: ", res);
          return res.data;
        }),

    getCategory: async (authToken) =>
      await axios
        .get(API_SERVER_URL + "/api/admin/lu/directory/1/category", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call====>> categoryList: ", res);
          return res.data;
        }),

    getCategoryWithStatus: (directoryId, status, authToken) =>
      axios
        .get(
          API_SERVER_URL +
            `/api/admin/lu/directory/${directoryId}/category/${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call====>> categoryList: ", res);
          return res.data;
        }),

    changeDirectoryCategoryStatus: (status, authToken) =>
      axios
        .put(
          API_SERVER_URL + "/api/admin/lu/directory/category/status",
          status,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log(
            "API Call =====>> change directoryCategory status :",
            res
          );
          return res.data;
        }),

    updateDirectoryCategory: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/directory/category", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call =====>> update directoryCategory :", res);
          return res.data;
        }),
    getCategoryListByCategoryId: (categoryId, authToken) =>
      axios
        .get(
          API_SERVER_URL + "/api/admin/lu/directory/category/" + categoryId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call====>> categoryList: ", res);
          return res.data;
        }),
  },

  directory: {
    getDirectory: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/directory?status=1", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call ===>> DirectoryList ", res);
          return res.data;
        }),
  },

  jobEmployer: {
    createEmployer: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/employer", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call ===>> addNewEmployer ", res);
          return res.data;
        }),

    editEmployer: async (data, authToken) => {
      try {
        const res = await axios.put(
          API_SERVER_URL + "/api/admin/lu/employer",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        console.log("API call ===>> updateNewEmployer", res);
        return res.data;
      } catch (err) {
        console.error(err);
      }
    },

    getEmployer: (status, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/employer/status/" + status, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call ===>> list of Employer ", res);
          return res.data;
        }),
  },

  industryList: {
    showIndustryList: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/industry", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call ===>>  industry list ", res);
          return res.data;
        }),
  },

  applicationList: {
    getJobApplicationList: (authToken, status) =>
      axios
        .get(
          API_SERVER_URL + "/api/admin/lu/job_application/status/" + status,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API call ===> Job Application List", res);
          return res.data;
        }),

    referCandidate: (authToken, data) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/job_application/status", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> referred", res);
          return res.data;
        }),
  },

  mobileUserList: {
    getUsersList: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/users", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> MobileUserList", res);
          return res.data;
        }),
  },
  newPassword: {
    resetPassword: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/auth/password", data, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API Call ===> reset Password", res);
          return res.data;
        }),
  },
  dashboardReport: {
    getJobApplicationStats: (authToken) =>
      axios
        .get(
          API_SERVER_URL + "/api/admin/lu/dashboard/stats/job_applications",
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          console.log("API Call ===> reset Password", res);
          return res.data;
        }),

    getJobCountLocationVise: (authToken) =>
      axios
        .get(
          API_SERVER_URL +
            "/api/admin/lu/dashboard/report/job_count_by_location",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((result) => {
          console.log(
            "API call =====>> get job count location vise : ",
            result
          );
          return result.data;
        }),
  },
  // Training Module //
  onlineVideoModule: {
    // Create
    createOnlineVideo: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/training/online_video", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log("API call ===>> addOnlineVideo ", res);
          return res.data;
        }),
    // Get All Comment Data
    getAllCommentData: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/training/online_video/AllCommentsData", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
         // adminReplyToUserComment
    adminReplyToUserComment: (data, authToken) =>
    axios
      .post(API_SERVER_URL + "/api/admin/lu/training/online_video/comment", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        return res.data;
      }),
    // Get List
    onlineVideoListModule: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/training/online_video", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
    // Get Training details by ID => Use while editing details
    getTrainingModuleById: (authToken, onlineVideoTrainingId) =>
      axios
        .get(
          API_SERVER_URL +
            "/api/admin/lu/training/online_video/" +
            onlineVideoTrainingId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),
    // Update training details API
    updateTrainingVideo: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/training/online_video", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),

    // display list for the events
    getTrainingCategory: async (authToken) =>
      await axios
        .get(API_SERVER_URL + "/api/admin/lu/directory/2/category", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
  },

  // Event module
  eventModule: {
    // Get Event List
    getEventList: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/admin/lu/training/event", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),

        // Create Event
      createEvent: (authToken, data) =>
      axios
        .post(API_SERVER_URL + "/api/admin/lu/training/event", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          return response.data;
        }),
        // update Event
      updateEvent: (authToken, data) =>
      axios
        .put(API_SERVER_URL + "/api/admin/lu/training/event", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          return response.data;
        }),
      // Get Event details by ID => Use while editing details
      getEventById: (authToken, eventId) =>
      axios
        .get(
          API_SERVER_URL + "/api/admin/lu/training/event/" + eventId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),

  },
};
